import React from 'react';

const DIETARY_RESTRICTIONS = [
  { id: 'halal', label: 'Halal' },
  { id: 'kosher', label: 'Kosher' },
  { id: 'vegan', label: 'Vegan' },
  { id: 'vegetarian', label: 'Vegetarian' },
  { id: 'dairy-free', label: 'Dairy-Free' },
  { id: 'gluten-free', label: 'Gluten-Free' },
  { id: 'keto-friendly', label: 'Keto' },
  { id: 'tree-nut-free', label: 'Tree Nut-Free' },
  { id: 'shellfish-free', label: 'Shellfish-Free' }
];

function DietaryRestrictions({ restrictions, setRestrictions }) {
  const handleRestrictionChange = (id) => {
    setRestrictions(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <div className="mb-6">
      <h2 className="text-xl font-semibold mb-4">Dietary Restrictions</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {DIETARY_RESTRICTIONS.map(({ id, label }) => (
          <div key={id} className="flex items-center">
            <input
              type="checkbox"
              id={id}
              checked={restrictions[id] || false}
              onChange={() => handleRestrictionChange(id)}
              className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            />
            <label htmlFor={id} className="ml-2 text-sm text-gray-700">{label}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DietaryRestrictions;