import axios from 'axios';


const API_BASE_URL = 'https://flat-cell-2e7d.anvar-shirinbayli.workers.dev'

const EDAMAM_APP_ID = process.env.REACT_APP_EDAMAM_APP_ID;
const EDAMAM_APP_KEY = process.env.REACT_APP_EDAMAM_APP_KEY;
const EDAMAM_API_BASE_URL = 'https://flat-cell-2e7d.anvar-shirinbayli.workers.dev/api/api/recipes/v2';

const NUTRITION_API_BASE_URL = 'https://flat-cell-2e7d.anvar-shirinbayli.workers.dev/api/nutrition-data';
// const NUTRITION_APP_ID = process.env.REACT_APP_NUTR_APP_ID;
// const NUTRITION_APP_KEY = process.env.REACT_APP_NUTR_APP_ID;


// Rate limiting settings
const RATE_LIMIT = 30;
const RATE_PERIOD = 60;

class RateLimiter {
  constructor(maxRequests, perSeconds) {
    this.maxRequests = maxRequests;
    this.perSeconds = perSeconds;
    this.requests = [];
  }

  canMakeRequest() {
    const now = Date.now();
    this.requests = this.requests.filter(time => now - time < this.perSeconds * 1000);
    if (this.requests.length < this.maxRequests) {
      this.requests.push(now);
      return true;
    }
    return false;
  }

  async waitForSlot() {
    return new Promise(resolve => {
      const checkSlot = () => {
        if (this.canMakeRequest()) {
          resolve();
        } else {
          setTimeout(checkSlot, 100);
        }
      };
      checkSlot();
    });
  }
}

const rateLimiter = new RateLimiter(RATE_LIMIT, RATE_PERIOD);

function capitalizeWords(str) {
  return str.replace(/\b\w/g, l => l.toUpperCase());
}

// Updated convertToMetric function
function convertToMetric(quantity, unit) {
  switch (unit.toLowerCase()) {
    case 'ounce':
    case 'oz':
      return { quantity: quantity * 28.35, unit: 'g' };
    case 'pound':
    case 'lb':
      return { quantity: quantity * 453.592, unit: 'g' };
    case 'cup':
      return { quantity: quantity * 236.588, unit: 'ml' };
    case 'tablespoon':
    case 'tbsp':
      return { quantity: quantity * 14.7868, unit: 'ml' };
    case 'teaspoon':
    case 'tsp':
      return { quantity: quantity * 4.92892, unit: 'ml' };
    case 'fl oz':
      return { quantity: quantity * 29.5735, unit: 'ml' };
    case 'pint':
      return { quantity: quantity * 473.176, unit: 'ml' };
    case 'quart':
      return { quantity: quantity * 946.353, unit: 'ml' };
    case 'gallon':
    case 'gal':
      return { quantity: quantity * 3785.41, unit: 'ml' };
    default:
      return { quantity, unit };
  }
}

// Updated convertToImperial function
function convertToImperial(quantity, unit) {
  switch (unit.toLowerCase()) {
    case 'g':
      return quantity >= 453.592 ? { quantity: quantity / 453.592, unit: 'lb' } : { quantity: quantity / 28.35, unit: 'oz' };
    case 'ml':
      if (quantity >= 3785.41) return { quantity: quantity / 3785.41, unit: 'gal' };
      if (quantity >= 946.353) return { quantity: quantity / 946.353, unit: 'quart' };
      if (quantity >= 473.176) return { quantity: quantity / 473.176, unit: 'pint' };
      if (quantity >= 29.5735) return { quantity: quantity / 29.5735, unit: 'fl oz' };
      if (quantity >= 14.7868) return { quantity: quantity / 14.7868, unit: 'tbsp' };
      return { quantity: quantity / 4.92892, unit: 'tsp' };
    default:
      return { quantity, unit };
  }
}

const NON_HALAL_INGREDIENTS = [
  'pork', 'bacon', 'ham', 'alcohol', 'wine', 'beer', 'liquor', 'gelatin',
  'lard', 'pepsin', 'rennet', 'marshmallow', 'vanilla extract'
];

// const NON_KOSHER_INGREDIENTS = [
//   'pork', 'rabbit', 'horse meat', 'catfish', 'shark', 'eel', 'octopus', 'squid',
//   'shellfish', 'lobster', 'crab', 'clam', 'oyster', 'shrimp', 'gelatin'
// ];

async function searchRecipes(ingredients, macroRatio, targetCalories, numberOfMeals, restrictions = {}, simpleRecipes = false) {
  const MAX_INGREDIENTS_PER_QUERY = 5;
  let allRecipes = [];

  // Function to make API request
  const makeRequest = async (ingredientGroup) => {
    const params = new URLSearchParams({
      q: ingredientGroup.join(' OR '),
      calories: `0-${targetCalories}`,
      random: 'true',
      to: (numberOfMeals * 2).toString(),
      type: 'any',
      beta: 'true',
      sysTag: 'live'
    });

    if (macroRatio !== 'balanced') {
      params.append('diet', macroRatio);
    }

    if (simpleRecipes) {
      params.append('ingr', '1-7');
      params.append('time', '1-30');
    }

    Object.entries(restrictions).forEach(([key, value]) => {
      if (value && key !== 'halal') {
        if (key === 'kosher') {
          params.append('health', key);
        } else if (key === 'dairy-free') {
          params.append('health', 'dairy-free');
        } else if (key === 'keto-friendly') {
          params.append('health', 'keto-friendly');
        } else {
          params.append('health', key);
        }
      }
    });

    try {
      // console.log('Sending request to URL:', `${API_BASE_URL}/api/recipes/v2?${params.toString()}`);
      const response = await axios.get(`${API_BASE_URL}/api/recipes/v2?${params.toString()}`);
      // console.log('Received response status:', response.status);

      if (response.data && response.data.hits) {
        return response.data.hits;
      }
    } catch (error) {
      //console.error('Error in API request:', error);
    }
    return [];
  };

  // Split ingredients into groups and make requests
  for (let i = 0; i < ingredients.length; i += MAX_INGREDIENTS_PER_QUERY) {
    const ingredientGroup = ingredients.slice(i, i + MAX_INGREDIENTS_PER_QUERY);
    const recipes = await makeRequest(ingredientGroup);
    allRecipes = allRecipes.concat(recipes);
  }

  // Filter halal recipes if needed
  if (restrictions.halal) {
    allRecipes = allRecipes.filter(recipe => 
      !recipe.recipe.ingredientLines.some(ingredient => 
        NON_HALAL_INGREDIENTS.some(nonHalal => 
          ingredient.toLowerCase().includes(nonHalal)
        )
      )
    );
  }

  if (allRecipes.length === 0) {
    throw new Error('No recipes found for the given ingredients and restrictions. Try to simplify your search.');
  }

  // Shuffle and limit the recipes to the requested number
  return allRecipes
    .sort(() => 0.5 - Math.random())
    .slice(0, numberOfMeals);
}

export { searchRecipes };

export async function generateMealPlan(ingredients, macroRatio, targetCalories, numberOfPeople, numberOfDays, restrictions, simpleRecipes) {
  await rateLimiter.waitForSlot();
  const numberOfMeals = Math.max(3, Math.min(6, Math.floor(targetCalories / 500)));
  let allHits = [];
  const maxRetries = 3;

  // Ensure ingredients is an array
  if (!Array.isArray(ingredients)) {
    throw new Error('Ingredients must be an array');
  }

  if (ingredients.length === 0) {
    throw new Error('Please select at least one ingredient');
  }
  if (ingredients.length > 10) {
    throw new Error('Please select a maximum of 10 ingredients in total');
  }

  // Shuffle the ingredients array
  const shuffledIngredients = [...ingredients].sort(() => 0.5 - Math.random());

  for (let i = 0; i < maxRetries; i++) {
    // Take a subset of ingredients, reducing the number in each retry
    const ingredientsToUse = shuffledIngredients.slice(0, Math.max(1, shuffledIngredients.length - i * 2));
    try {
      allHits = await searchRecipes(ingredientsToUse, macroRatio, targetCalories, numberOfMeals * 2, restrictions, simpleRecipes);
      if (allHits.length >= numberOfMeals) {
        break;
      }
    } catch (error) {
      //console.error(`Error in retry ${i + 1}:`, error);
    }
  }

  if (allHits.length < numberOfMeals) {
    throw new Error(`Not enough suitable recipes found. Try reducing the number of ingredients or dietary restrictions.`);
  }

  // Shuffle the hits to get a random selection
  allHits = allHits.sort(() => 0.5 - Math.random());

  const meals = allHits.slice(0, numberOfMeals).map(hit => {
    const recipe = hit.recipe;
    const servings = recipe.yield || 1;
    return {
      title: recipe.label,
      calories: Math.round(recipe.calories / servings),
      protein: Math.round((recipe.totalNutrients?.PROCNT?.quantity || 0) / servings),
      carbs: Math.round((recipe.totalNutrients?.CHOCDF?.quantity || 0) / servings),
      fat: Math.round((recipe.totalNutrients?.FAT?.quantity || 0) / servings),
      fiber: Math.round((recipe.totalNutrients?.FIBTG?.quantity || 0) / servings),
      image: recipe.image,
      url: recipe.url,
      servings: servings,
      ingredients: recipe.ingredients.map(ing => ({
        ...ing,
        quantity: (ing.quantity || 0) / servings  // Adjust ingredient quantities to per-serving
      }))
    };
  });

  const adjustedMeals = adjustPortions(meals, targetCalories, macroRatio);
  
  return {
    mealPlan: adjustedMeals.meals,
    dailyTotals: adjustedMeals.dailyTotals,
    numberOfDays: numberOfDays,
    numberOfPeople: numberOfPeople
  };
}

function adjustPortions(meals, targetCalories, macroRatio) {
  const totalCalories = meals.reduce((sum, meal) => sum + meal.calories, 0);
  const scaleFactor = targetCalories / totalCalories;

  const adjustedMeals = meals.map(meal => {
    const adjustedCalories = Math.round(meal.calories * scaleFactor);
    const calorieAdjustmentFactor = adjustedCalories / meal.calories;
    
    return {
      ...meal,
      calories: adjustedCalories,
      protein: Math.round(meal.protein * calorieAdjustmentFactor),
      carbs: Math.round(meal.carbs * calorieAdjustmentFactor),
      fat: Math.round(meal.fat * calorieAdjustmentFactor),
      fiber: Math.round(meal.fiber * calorieAdjustmentFactor),
      ingredients: meal.ingredients.map(ing => ({
        ...ing,
        quantity: ing.quantity * calorieAdjustmentFactor
      }))
    };
  });

  const dailyTotals = adjustedMeals.reduce((totals, meal) => {
    totals.calories += meal.calories;
    totals.protein += meal.protein;
    totals.carbs += meal.carbs;
    totals.fat += meal.fat;
    totals.fiber += meal.fiber;
    return totals;
  }, { calories: 0, protein: 0, carbs: 0, fat: 0, fiber: 0 });

  return { meals: adjustedMeals, dailyTotals };
}


function normalizeQuantityAndUnit(quantity, unit, useMetric) {
  // Convert everything to metric first
  let metricQuantity, metricUnit;

  switch (unit.toLowerCase()) {
    case 'g':
    case 'gram':
    case 'grams':
      metricQuantity = quantity;
      metricUnit = 'g';
      break;
    case 'kg':
    case 'kilogram':
    case 'kilograms':
      metricQuantity = quantity * 1000;
      metricUnit = 'g';
      break;
    case 'ml':
    case 'milliliter':
    case 'milliliters':
      metricQuantity = quantity;
      metricUnit = 'ml';
      break;
    case 'l':
    case 'liter':
    case 'liters':
      metricQuantity = quantity * 1000;
      metricUnit = 'ml';
      break;
    case 'tbsp':
    case 'tablespoon':
    case 'tablespoons':
      metricQuantity = quantity * 15;
      metricUnit = 'ml';
      break;
    case 'tsp':
    case 'teaspoon':
    case 'teaspoons':
      metricQuantity = quantity * 5;
      metricUnit = 'ml';
      break;
    case 'cup':
    case 'cups':
      metricQuantity = quantity * 240;
      metricUnit = 'ml';
      break;
    case 'oz':
    case 'ounce':
    case 'ounces':
      metricQuantity = quantity * 28.35;
      metricUnit = 'g';
      break;
    case 'lb':
    case 'pound':
    case 'pounds':
      metricQuantity = quantity * 453.592;
      metricUnit = 'g';
      break;
    case 'fl oz':
    case 'fluid ounce':
    case 'fluid ounces':
      metricQuantity = quantity * 29.5735;
      metricUnit = 'ml';
      break;
    case 'pint':
    case 'pints':
      metricQuantity = quantity * 473.176;
      metricUnit = 'ml';
      break;
    case 'quart':
    case 'quarts':
      metricQuantity = quantity * 946.353;
      metricUnit = 'ml';
      break;
    case 'gallon':
    case 'gallons':
      metricQuantity = quantity * 3785.41;
      metricUnit = 'ml';
      break;
    default:
      return { quantity: Math.max(1, Math.round(quantity)), unit: unit === '<unit>' ? 'piece' : unit };
  }

  // Now normalize to a reasonable unit
  let normalizedQuantity, normalizedUnit;

  if (metricUnit === 'g') {
    if (metricQuantity >= 1000) {
      normalizedQuantity = metricQuantity / 1000;
      normalizedUnit = 'kg';
    } else {
      normalizedQuantity = metricQuantity;
      normalizedUnit = 'g';
    }
  } else if (metricUnit === 'ml') {
    if (metricQuantity >= 1000) {
      normalizedQuantity = metricQuantity / 1000;
      normalizedUnit = 'L';
    } else {
      normalizedQuantity = metricQuantity;
      normalizedUnit = 'ml';
    }
  }

  // Rounding logic
  function roundQuantity(value) {
    if (value < 0.1) {
      return Math.round(value * 100) / 100; // Round to 2 decimal places for very small quantities
    } else if (value < 1) {
      return Math.round(value * 10) / 10; // Round to 1 decimal place for quantities less than 1
    } else if (value < 10) {
      return Math.round(value * 2) / 2; // Round to nearest 0.5 for quantities between 1 and 10
    } else {
      return Math.round(value); // Round to whole number for quantities 10 and above
    }
  }

  normalizedQuantity = roundQuantity(normalizedQuantity);

  // Ensure we never round down to 0 for needed ingredients
  if (normalizedQuantity === 0 && quantity > 0) {
    normalizedQuantity = 0.1; // Set a minimum quantity
  }

  // Convert back to imperial if needed
  if (!useMetric) {
    if (normalizedUnit === 'g') {
      if (normalizedQuantity >= 453.592) {
        normalizedQuantity = roundQuantity(normalizedQuantity / 453.592);
        normalizedUnit = 'lb';
      } else {
        normalizedQuantity = roundQuantity(normalizedQuantity / 28.35);
        normalizedUnit = 'oz';
      }
    } else if (normalizedUnit === 'kg') {
      normalizedQuantity = roundQuantity(normalizedQuantity * 2.20462);
      normalizedUnit = 'lb';
    } else if (normalizedUnit === 'ml') {
      if (normalizedQuantity >= 946.353) {
        normalizedQuantity = roundQuantity(normalizedQuantity / 946.353);
        normalizedUnit = 'quart';
      } else if (normalizedQuantity >= 473.176) {
        normalizedQuantity = roundQuantity(normalizedQuantity / 473.176);
        normalizedUnit = 'pint';
      } else if (normalizedQuantity >= 29.5735) {
        normalizedQuantity = roundQuantity(normalizedQuantity / 29.5735);
        normalizedUnit = 'fl oz';
      } else {
        normalizedQuantity = roundQuantity(normalizedQuantity / 4.92892);
        normalizedUnit = 'tsp';
      }
    } else if (normalizedUnit === 'L') {
      normalizedQuantity = roundQuantity(normalizedQuantity * 4.22675);
      normalizedUnit = 'quart';
    }
  }

  return { quantity: normalizedQuantity, unit: normalizedUnit };
}

export function generateShoppingList(mealPlan, numberOfPeople, numberOfDays, useMetric = true) {
  const ingredientMap = new Map();

  const isNoRecipeMode = Array.isArray(mealPlan.ingredients);
  const ingredients = isNoRecipeMode ? mealPlan.ingredients : mealPlan.mealPlan;

  ingredients.forEach(item => {
    const ingredientItems = isNoRecipeMode ? [item] : item.ingredients;

    ingredientItems.forEach(ingredient => {
      const key = (ingredient.food || ingredient.name).toLowerCase();
      let quantity, unit;

      if (isNoRecipeMode) {
        quantity = ingredient.amount; // Already adjusted for numberOfPeople and numberOfDays
        unit = ingredient.unit === '<unit>' ? 'piece' : (ingredient.unit || 'g');
      } else {
        quantity = (ingredient.quantity || 0) * numberOfPeople * numberOfDays;
        unit = ingredient.measure === '<unit>' ? 'piece' : (ingredient.measure || 'g');
      }

      if (ingredientMap.has(key)) {
        const existingItem = ingredientMap.get(key);
        existingItem.quantity += quantity;
        // Keep the new unit if it's more specific (not 'piece')
        if (unit !== 'piece' && existingItem.unit === 'piece') {
          existingItem.unit = unit;
        }
      } else {
        ingredientMap.set(key, { quantity, unit });
      }
    });
  });

  const shoppingList = Array.from(ingredientMap, ([name, { quantity, unit }]) => {
    let normalizedQuantity, normalizedUnit;

    if (useMetric) {
      const converted = convertToMetric(quantity, unit);
      normalizedQuantity = Math.round(converted.quantity);
      normalizedUnit = converted.unit === '<unit>' ? 'piece' : converted.unit;
    } else {
      const converted = convertToImperial(quantity, unit);
      normalizedQuantity = converted.quantity.toFixed(2);
      normalizedUnit = converted.unit === '<unit>' ? 'piece' : converted.unit;
    }

    return {
      name: capitalizeWords(name),
      quantity: normalizedQuantity,
      unit: normalizedUnit
    };
  });

  // Filter out items with 0 quantity
  const filteredShoppingList = shoppingList.filter(item => item.quantity > 0);

  // Sort the shopping list by quantity in descending order
  filteredShoppingList.sort((a, b) => b.quantity - a.quantity);

  return filteredShoppingList;
}

// function normalizeToMetric(quantity, unit) {
//   switch (unit.toLowerCase()) {
//     case 'kg':
//       return { quantity: Math.round(quantity * 1000), unit: 'g' };
//     case 'l':
//       return { quantity: Math.round(quantity * 1000), unit: 'ml' };
//     case 'g':
//     case 'ml':
//       return { quantity: Math.round(quantity), unit };
//     default:
//       return { quantity: Math.round(quantity), unit };
//   }
// }

// Helper function to convert to metric
// function convertToMetric(quantity, unit) {
//   switch (unit.toLowerCase()) {
//     case 'kg':
//       return { quantity: quantity * 1000, unit: 'g' };
//     case 'l':
//       return { quantity: quantity * 1000, unit: 'ml' };
//     // Add more conversions as needed
//     default:
//       return { quantity, unit };
//   }
// }


/// Function to get nutritional data (from local storage or API)
async function getNutritionData(ingredient) {
  //console.log(`Fetching data for ${ingredient}`);
  try {
    const response = await axios.get(`${NUTRITION_API_BASE_URL}/${encodeURIComponent(ingredient)}`);
    return response.data;
  } catch (error) {
    //console.error(`Error fetching nutrition data for ${ingredient}:`, error.response?.data || error.message);
    throw new Error(`Failed to fetch nutrition data for ${ingredient}`);
  }
}



export async function calculateNoRecipeMode(ingredients, macroRatio, targetCalories, numberOfPeople, numberOfDays) {
  const macros = {
    balanced: { protein: 0.3, fat: 0.3, carbs: 0.4 },
    'high-protein': { protein: 0.4, fat: 0.3, carbs: 0.3 },
    'low-carb': { protein: 0.35, fat: 0.45, carbs: 0.2 },
    'low-fat': { protein: 0.35, fat: 0.2, carbs: 0.45 }
  };

  const selectedMacros = macros[macroRatio];
  const caloriesPerGram = {
    protein: 4,
    carbs: 4,
    fat: 9
  };

  const totalTargetCalories = targetCalories * numberOfPeople * numberOfDays;
  const targetProtein = (totalTargetCalories * selectedMacros.protein) / caloriesPerGram.protein;
  const targetCarbs = (totalTargetCalories * selectedMacros.carbs) / caloriesPerGram.carbs;
  const targetFat = (totalTargetCalories * selectedMacros.fat) / caloriesPerGram.fat;

  const ingredientData = await Promise.all(ingredients.map(async (ingredient) => {
    const nutritionData = await getNutritionData(ingredient);
    return {
      name: ingredient,
      calories: nutritionData.calories,
      protein: nutritionData.protein,
      carbs: nutritionData.carbs,
      fat: nutritionData.fat
    };
  }));

  const ingredientPlan = ingredientData.map(ing => {
    let amount;
    if (ing.protein > ing.carbs && ing.protein > ing.fat) {
      amount = (targetProtein / ing.protein) * 100;
    } else if (ing.carbs > ing.protein && ing.carbs > ing.fat) {
      amount = (targetCarbs / ing.carbs) * 100;
    } else {
      amount = (targetFat / ing.fat) * 100;
    }
    return { 
      name: ing.name, 
      amount: Math.round(amount), 
      unit: 'g',
      calories: Math.round((amount / 100) * ing.calories),
      protein: Math.round((amount / 100) * ing.protein),
      carbs: Math.round((amount / 100) * ing.carbs),
      fat: Math.round((amount / 100) * ing.fat)
    };
  });


  // Adjust quantities to match target calories
  const totalNutrients = ingredientPlan.reduce((acc, ing) => ({
    calories: acc.calories + ing.calories,
    protein: acc.protein + ing.protein,
    carbs: acc.carbs + ing.carbs,
    fat: acc.fat + ing.fat
  }), { calories: 0, protein: 0, carbs: 0, fat: 0 });

  const calorieAdjustmentFactor = totalTargetCalories / totalNutrients.calories;
  const adjustedIngredientPlan = ingredientPlan.map(ing => ({
    ...ing,
    amount: Math.round(ing.amount * calorieAdjustmentFactor),
    calories: Math.round(ing.calories * calorieAdjustmentFactor),
    protein: Math.round(ing.protein * calorieAdjustmentFactor),
    carbs: Math.round(ing.carbs * calorieAdjustmentFactor),
    fat: Math.round(ing.fat * calorieAdjustmentFactor)
  }));

  const adjustedTotalNutrients = adjustedIngredientPlan.reduce((acc, ing) => ({
    calories: acc.calories + ing.calories,
    protein: acc.protein + ing.protein,
    carbs: acc.carbs + ing.carbs,
    fat: acc.fat + ing.fat
  }), { calories: 0, protein: 0, carbs: 0, fat: 0 });

  // Calculate daily totals per person
  const dailyTotals = {
    calories: Math.round(adjustedTotalNutrients.calories / numberOfPeople / numberOfDays),
    protein: Math.round(adjustedTotalNutrients.protein / numberOfPeople / numberOfDays),
    carbs: Math.round(adjustedTotalNutrients.carbs / numberOfPeople / numberOfDays),
    fat: Math.round(adjustedTotalNutrients.fat / numberOfPeople / numberOfDays)
  };

  return {
    ingredients: adjustedIngredientPlan,
    dailyTotals,
    numberOfDays,
    numberOfPeople
  };
}