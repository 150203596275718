import React from 'react';

function ShoppingList({ list }) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <h2 className="text-2xl font-bold mb-4">Shopping List</h2>
      <ul className="list-none pl-0">
        {list.map((item, index) => (
          <li key={index} className="mb-2 flex justify-between items-center border-b pb-2">
            <span className="font-medium">{item.name}</span>
            <span>{item.quantity} {item.unit}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ShoppingList;