import React, { useState, useEffect } from 'react';

function NoRecipeModeIngredients({ initialIngredients, targetCalories, macroRatio, numberOfPeople, numberOfDays, onIngredientsChange }) {
  const [ingredients, setIngredients] = useState([]);
  const [tempInputs, setTempInputs] = useState({});
  const MAX_AMOUNT = 10000;
  const MIN_AMOUNT = 1;
  const CALORIE_TOLERANCE = 0.1; // 10% tolerance

  useEffect(() => {
    if (Array.isArray(initialIngredients) && initialIngredients.length > 0) {
      setIngredients(initialIngredients.map(ing => ({
        ...ing,
        locked: false,
        originalRatios: {
          calories: ing.calories / ing.amount,
          protein: ing.protein / ing.amount,
          carbs: ing.carbs / ing.amount,
          fat: ing.fat / ing.amount
        }
      })));
    } else {
      setIngredients([
        { name: 'Protein', amount: 100, unit: 'g', calories: 400, protein: 100, carbs: 0, fat: 0, locked: false },
        { name: 'Carbs', amount: 100, unit: 'g', calories: 400, protein: 0, carbs: 100, fat: 0, locked: false },
        { name: 'Fat', amount: 100, unit: 'g', calories: 900, protein: 0, carbs: 0, fat: 100, locked: false }
      ].map(ing => ({
        ...ing,
        originalRatios: {
          calories: ing.calories / ing.amount,
          protein: ing.protein / ing.amount,
          carbs: ing.carbs / ing.amount,
          fat: ing.fat / ing.amount
        }
      })));
    }
  }, [initialIngredients]);

  useEffect(() => {
    // Notify parent component of ingredient changes
    onIngredientsChange(ingredients);
  }, [ingredients, onIngredientsChange]);

  const handleIngredientChange = (index, value) => {
    setTempInputs(prev => ({ ...prev, [index]: value }));
    
    if (value === '' || isNaN(parseInt(value, 10))) return;

    const parsedAmount = Math.min(MAX_AMOUNT, Math.max(MIN_AMOUNT, parseInt(value, 10)));
    updateIngredient(index, parsedAmount * numberOfPeople * numberOfDays);
  };

  const handleIngredientBlur = (index) => {
    const value = tempInputs[index];
    if (value === undefined) return;

    let parsedAmount;
    if (value === '' || isNaN(parseInt(value, 10))) {
      parsedAmount = MIN_AMOUNT;
    } else {
      parsedAmount = Math.min(MAX_AMOUNT, Math.max(MIN_AMOUNT, parseInt(value, 10)));
    }

    updateIngredient(index, parsedAmount * numberOfPeople * numberOfDays);
    setTempInputs(prev => {
      const newInputs = { ...prev };
      delete newInputs[index];
      return newInputs;
    });
  };

  const updateIngredient = (index, totalAmount) => {
    setIngredients(prevIngredients => {
      const updatedIngredients = prevIngredients.map((ing, i) => {
        if (i === index) {
          return {
            ...ing,
            amount: totalAmount,
            calories: Math.round(totalAmount * ing.originalRatios.calories),
            protein: Math.round(totalAmount * ing.originalRatios.protein),
            carbs: Math.round(totalAmount * ing.originalRatios.carbs),
            fat: Math.round(totalAmount * ing.originalRatios.fat)
          };
        }
        return ing;
      });
      
      return updatedIngredients;
    });
  };

  const adjustIngredientsToTarget = () => {
    setIngredients(prevIngredients => {
      const totalTargetCalories = targetCalories * numberOfPeople * numberOfDays;
      const lockedCalories = prevIngredients.reduce((sum, ing) => ing.locked ? sum + ing.calories : sum, 0);
      const unlockedIngredients = prevIngredients.filter(ing => !ing.locked);
      const totalUnlockedCalories = unlockedIngredients.reduce((sum, ing) => sum + ing.calories, 0);

      if (totalUnlockedCalories === 0) return prevIngredients; // Prevent division by zero

      const remainingCalories = Math.max(0, totalTargetCalories - lockedCalories);
      const scaleFactor = remainingCalories / totalUnlockedCalories;

      return prevIngredients.map(ing => {
        if (ing.locked) return ing;
        const newAmount = Math.max(MIN_AMOUNT, Math.round(ing.amount * scaleFactor));
        return {
          ...ing,
          amount: newAmount,
          calories: Math.round(newAmount * ing.originalRatios.calories),
          protein: Math.round(newAmount * ing.originalRatios.protein),
          carbs: Math.round(newAmount * ing.originalRatios.carbs),
          fat: Math.round(newAmount * ing.originalRatios.fat)
        };
      });
    });
  };


  const toggleLock = (index) => {
    setIngredients(prevIngredients => 
      prevIngredients.map((ing, i) => 
        i === index ? {...ing, locked: !ing.locked} : ing
      )
    );
  };

  const calculateTotals = () => {
    const totals = ingredients.reduce((totals, ing) => ({
      calories: totals.calories + ing.calories,
      protein: totals.protein + ing.protein,
      carbs: totals.carbs + ing.carbs,
      fat: totals.fat + ing.fat
    }), { calories: 0, protein: 0, carbs: 0, fat: 0 });

    // Convert totals to per person per day
    return {
      calories: Math.round(totals.calories / numberOfPeople / numberOfDays),
      protein: Math.round(totals.protein / numberOfPeople / numberOfDays),
      carbs: Math.round(totals.carbs / numberOfPeople / numberOfDays),
      fat: Math.round(totals.fat / numberOfPeople / numberOfDays)
    };
  };

  const totals = calculateTotals();
  const isWithinTolerance = Math.abs(totals.calories - targetCalories) <= targetCalories * CALORIE_TOLERANCE;

  const convertToImperial = (grams) => {
    const ounces = grams * 0.035274;
    if (ounces < 16) {
      return `${ounces.toFixed(1)} oz`;
    } else {
      const pounds = ounces / 16;
      return `${pounds.toFixed(1)} lb`;
    }
  };


  if (ingredients.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Ingredient Plan</h2>
      <div className="space-y-4">
        {ingredients.map((ing, index) => {
          const displayAmount = Math.round(ing.amount / numberOfPeople / numberOfDays);
          const displayCalories = Math.round(ing.calories / numberOfPeople / numberOfDays);
          const displayProtein = Math.round(ing.protein / numberOfPeople / numberOfDays);
          const displayCarbs = Math.round(ing.carbs / numberOfPeople / numberOfDays);
          const displayFat = Math.round(ing.fat / numberOfPeople / numberOfDays);
          const imperialAmount = convertToImperial(displayAmount);

          return (
            <div key={index} className="bg-gray-50 rounded-md p-4 shadow-sm">
              <div className="flex justify-between items-center mb-2">
                <span className="font-semibold text-lg">{ing.name}</span>
                <div className="flex items-center">
                  <span className="text-sm mr-2">Lock</span>
                  <input
                    type="checkbox"
                    checked={ing.locked}
                    onChange={() => toggleLock(index)}
                    className="w-5 h-5"
                  />
                </div>
              </div>
              <div className="flex items-center mb-2">
                <input
                  type="text"
                  value={tempInputs[index] !== undefined ? tempInputs[index] : displayAmount}
                  onChange={(e) => handleIngredientChange(index, e.target.value)}
                  onBlur={() => handleIngredientBlur(index)}
                  disabled={ing.locked}
                  className={`flex-grow px-2 py-1 border rounded mr-2 text-lg ${ing.locked ? 'bg-gray-200' : ''}`}
                />
                <div className="text-right">
                  <div>{displayAmount} {ing.unit}</div>
                  <div className="text-sm text-gray-500">({imperialAmount})</div>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-2 text-sm text-gray-600">
                <div>Cal: {displayCalories}</div>
                <div>P: {displayProtein}g</div>
                <div>C: {displayCarbs}g</div>
                <div>F: {displayFat}g</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={`mt-6 p-4 bg-gray-100 rounded-lg ${!isWithinTolerance ? 'border-2 border-red-500' : ''}`}>
        <h3 className="text-lg font-semibold mb-2 text-gray-800">Daily Totals (per person):</h3>
        <ul className="space-y-1 text-sm">
          <li>Calories: {totals.calories} (Target: {targetCalories})</li>
          <li>Protein: {totals.protein}g</li>
          <li>Carbs: {totals.carbs}g</li>
          <li>Fat: {totals.fat}g</li>
        </ul>
        {!isWithinTolerance && (
          <p className="mt-2 text-sm text-red-600">
            Warning: Total calories are significantly different from the target.
          </p>
        )}
      </div>
      <button
        onClick={adjustIngredientsToTarget}
        className="w-full mt-4 bg-gray-800 text-white py-3 px-4 rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition duration-200 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Adjust to Target Calories
      </button>
    </div>
  );
}

export default NoRecipeModeIngredients;