import React from 'react';

const MACRO_RATIOS = [
  { id: 'balanced', label: 'Balanced (Protein/Fat/Carb values in 15/35/50 ratio)' },
  { id: 'high-protein', label: 'High-Protein (More than 50% of total calories from proteins)' },
  { id: 'low-carb', label: 'Low-Carb (Less than 20% of total calories from carbs)' },
  { id: 'low-fat', label: 'Low-Fat (Less than 15% of total calories from fat)' }
];

function MacroRatioSelector({ macroRatio, setMacroRatio }) {
  return (
    <div className="mb-6">
      <h2 className="text-xl font-semibold mb-4">Select Macro Ratio</h2>
      <div className="space-y-2">
        {MACRO_RATIOS.map(ratio => (
          <label key={ratio.id} className="flex items-center">
            <input
              type="radio"
              value={ratio.id}
              checked={macroRatio === ratio.id}
              onChange={(e) => setMacroRatio(e.target.value)}
              className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            />
            <span className="ml-2 text-sm text-gray-700">{ratio.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
}

export default MacroRatioSelector;