import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoImage from './preppy-logo.png'; // Adjust the path as needed


async function fetchWithRetry(url, retries = 3, delay = 1000) {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url);
      if (response.ok) return response;
    } catch (error) {
      //console.error(`Attempt ${i + 1} failed: ${error.message}`);
    }
    await new Promise(resolve => setTimeout(resolve, delay));
  }
  throw new Error(`Failed to fetch after ${retries} attempts`);
}

function formatIngredientUnit(quantity, measure) {
  if (!measure || measure === '<unit>') {
    return quantity ? `${quantity.toFixed(2)}` : '';
  }
  return `${quantity.toFixed(2)} ${measure}`;
}

async function getProxiedImageAsBase64(url) {
  //console.log(`Starting to fetch image: ${url}`);
  try {
    const proxyUrl = url.replace(
      'https://edamam-product-images.s3.amazonaws.com',
      'https://flat-cell-2e7d.anvar-shirinbayli.workers.dev/img'
    );
    //console.log(`Fetching from proxy URL: ${proxyUrl}`);
    const response = await fetch(proxyUrl);
    //console.log(`Fetch response status: ${response.status}`);
    //console.log(`Response headers:`, Object.fromEntries(response.headers));

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get('Content-Type');
    //console.log(`Content-Type: ${contentType}`);

    // If Content-Type is missing or not an image type, infer from the URL
    if (!contentType || !contentType.startsWith('image/')) {
      const fileExtension = url.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension)) {
        //console.log(`Inferred image type from URL: image/${fileExtension}`);
      } else {
        //console.warn(`Received non-image content. Falling back to placeholder.`);
        return null;
      }
    }

    const blob = await response.blob();
    //console.log(`Blob type: ${blob.type}, size: ${blob.size} bytes`);
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
       // console.log(`FileReader onloadend called`);
        //console.log(`Image data (first 100 chars): ${reader.result.substring(0, 100)}`);
        resolve(reader.result);
      };
      reader.onerror = (error) => {
       // console.error('FileReader error:', error);
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
   // console.error('Error fetching image:', error);
    return null;
  }
}


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const exportToPDF = async (mealPlan, shoppingList, noRecipeMode) => {
  //console.log('Starting PDF export');
  const pdf = new jsPDF();
  
  const pageWidth = pdf.internal.pageSize.width;
  const pageHeight = pdf.internal.pageSize.height;

  // Function to add centered wrapped text
  const addCenteredWrappedText = (text, y, maxWidth, lineHeight) => {
   // console.log(`Adding centered wrapped text: "${text.substring(0, 20)}..."`);
    const lines = pdf.splitTextToSize(text, maxWidth);
    lines.forEach((line, i) => {
      const textWidth = pdf.getStringUnitWidth(line) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;
      pdf.text(line, x, y + (i * lineHeight));
    });
    return y + (lines.length * lineHeight);
  };

  // Add logo
  try {
    const logoWidth = 50;
    const logoHeight = 50;
    const logoX = (pageWidth - logoWidth) / 2;
    pdf.addImage(logoImage, 'PNG', logoX, 10, logoWidth, logoHeight);
    //console.log('Logo added successfully');
  } catch (error) {
    //console.error('Error adding logo:', error);
  }

  // Add title
  let yOffset = 70;
  pdf.setFontSize(24);
  yOffset = addCenteredWrappedText('Preppy \n Meal Plan', yOffset, 180, 10);
  pdf.setFontSize(16);
  yOffset = addCenteredWrappedText(`For ${mealPlan.numberOfPeople} person(s), ${mealPlan.numberOfDays} day(s)`, yOffset + 10, 180, 8);


  // // Determine number of people and days
  // let numberOfPeople, numberOfDays;
  // if (noRecipeMode) {
  //   numberOfPeople = mealPlan.numberOfPeople;
  //   numberOfDays = mealPlan.numberOfDays;
  // } else {
  //   numberOfPeople = mealPlan.numberOfPeople;
  //   numberOfDays = mealPlan.numberOfDays;
  // }

  // // Log the values for debugging
  // console.log(`Number of people: ${numberOfPeople}, Number of days: ${numberOfDays}`);

  // yOffset = addCenteredWrappedText(`For ${numberOfPeople || 'N/A'} person(s), ${numberOfDays || 'N/A'} day(s)`, yOffset + 10, 180, 8);


  // Add daily totals to the first page
  yOffset += 20;
  pdf.setFontSize(18);
  yOffset = addCenteredWrappedText('Daily Totals (per person)', yOffset, 180, 10);
  pdf.setFontSize(14);
  yOffset += 10;

  let dailyTotals;
  if (noRecipeMode) {
    // Calculate daily totals for No Recipe Mode
    dailyTotals = mealPlan.ingredients.reduce((totals, ingredient) => {
      totals.calories += ingredient.calories || 0;
      totals.protein += ingredient.protein || 0;
      totals.carbs += ingredient.carbs || 0;
      totals.fat += ingredient.fat || 0;
      return totals;
    }, { calories: 0, protein: 0, carbs: 0, fat: 0 });

    // Adjust for number of days and people
    const totalDays = mealPlan.numberOfDays || 1;
    const totalPeople = mealPlan.numberOfPeople || 1;
    Object.keys(dailyTotals).forEach(key => {
      dailyTotals[key] = dailyTotals[key] / totalDays / totalPeople;
    });
  } else {
    dailyTotals = mealPlan.dailyTotals;
  }

  if (dailyTotals) {
    yOffset = addCenteredWrappedText(`Calories: ${Math.round(dailyTotals.calories) || 'N/A'}`, yOffset, 180, 7);
    yOffset = addCenteredWrappedText(`Protein: ${Math.round(dailyTotals.protein) || 'N/A'}g`, yOffset + 7, 180, 7);
    yOffset = addCenteredWrappedText(`Carbs: ${Math.round(dailyTotals.carbs) || 'N/A'}g`, yOffset + 7, 180, 7);
    yOffset = addCenteredWrappedText(`Fat: ${Math.round(dailyTotals.fat) || 'N/A'}g`, yOffset + 7, 180, 7);
    if (dailyTotals.fiber !== undefined) {
      yOffset = addCenteredWrappedText(`Fiber: ${Math.round(dailyTotals.fiber) || 'N/A'}g`, yOffset + 7, 180, 7);
    }
  } else {
    yOffset = addCenteredWrappedText('Daily totals not available', yOffset, 180, 7);
  }

  //console.log('First page with totals added');

  if (noRecipeMode) {
    // Add ingredient list for No Recipe Mode
   // console.log('Adding ingredient list for No Recipe Mode');
    pdf.addPage();
    yOffset = 20;

    pdf.setFontSize(18);
    yOffset = addCenteredWrappedText('Ingredient List', yOffset, 180, 10);

    pdf.setFontSize(12);
    yOffset += 10;

    pdf.autoTable({
      startY: yOffset,
      head: [['Ingredient', 'Amount']],
      body: shoppingList.map(item => [
        capitalizeFirstLetter(item.name),
        `${item.quantity} ${item.unit}`
      ]),
      theme: 'striped',
      headStyles: { fillColor: [100, 100, 100] },
      styles: { fontSize: 10, cellPadding: 5 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto', halign: 'right' }
      },
      margin: { left: 20, right: 20 },
    });

    yOffset = pdf.lastAutoTable.finalY + 10;
  } else {
    // Add meal plan (existing code for regular mode)
    //console.log('Adding meal plan');
    for (const meal of mealPlan.mealPlan) {
      pdf.addPage();
      yOffset = 20;

      // Add meal title
      pdf.setFontSize(18);
      yOffset = addCenteredWrappedText(meal.title, yOffset, 180, 10);

      // Add meal image
      if (meal.image) {
        try {
          //console.log(`Attempting to add image for meal: ${meal.title}`);
          const imageData = await getProxiedImageAsBase64(meal.image);
          if (imageData) {
            const imgWidth = 100;
            const imgHeight = 100;
            const imgX = (pageWidth - imgWidth) / 2;
            pdf.addImage(imageData, 'PNG', imgX, yOffset + 10, imgWidth, imgHeight, undefined, 'FAST');
           // console.log(`Image added for meal: ${meal.title}`);
            yOffset += imgHeight + 20;
          } else {
            //console.log(`No image data received for meal: ${meal.title}`);
            yOffset += 10;
          }
        } catch (error) {
         // console.error(`Error adding image for meal: ${meal.title}`, error);
          yOffset += 10;
        }
      } else {
        //console.log(`No image URL provided for meal: ${meal.title}`);
      }

      // Add nutritional information
      pdf.setFontSize(12);
      yOffset = addCenteredWrappedText(`Calories: ${meal.calories}, Protein: ${meal.protein}g, Carbs: ${meal.carbs}g, Fat: ${meal.fat}g`, yOffset, 180, 7);

      // Add ingredients table
      pdf.setFontSize(14);
      yOffset = addCenteredWrappedText('Ingredients:', yOffset + 10, 180, 7);

      pdf.autoTable({
        startY: yOffset + 5,
        head: [['Ingredient', 'Amount']],
        body: meal.ingredients.map(ing => [
          capitalizeFirstLetter(ing.food),
          formatIngredientUnit(ing.quantity, ing.measure)
        ]),
        theme: 'striped',
        headStyles: { fillColor: [100, 100, 100] },
        styles: { fontSize: 10, halign: 'center' },
        columnStyles: { 0: { halign: 'left' } },
        margin: { left: 20, right: 20 },
      });

      yOffset = pdf.lastAutoTable.finalY + 10;

      // Add recipe URL
      pdf.setFontSize(12);
      addCenteredWrappedText('Full Recipe:', yOffset, 180, 7);
      yOffset += 10;
      pdf.setTextColor(0, 0, 255);  // Set text color to blue for URL
      addCenteredWrappedText(meal.url, yOffset, 180, 7);
      pdf.setTextColor(0);  // Reset text color to black
    }
    //console.log('Meal plan added');
  }

  // Add shopping list
  pdf.addPage();
  pdf.setFontSize(18);
  addCenteredWrappedText('Shopping List', 20, 180, 10);
  
  // Add note about rounded quantities
  pdf.setFontSize(10);
  pdf.setTextColor(100);  // Grey color
  const note = "Note: Quantities in this shopping list are rounded for convenience. Please refer to individual recipes for precise measurements when cooking.";
  addCenteredWrappedText(note, 35, 160, 5);
  
  pdf.setTextColor(0);  // Reset to black
  //console.log('Adding shopping list table');
  pdf.autoTable({
    startY: 50,
    head: [['Ingredient', 'Amount']],
    body: shoppingList.map(item => [capitalizeFirstLetter(item.name), `${item.quantity} ${item.unit}`]),
    theme: 'striped',
    headStyles: { fillColor: [100, 100, 100] },
    styles: { fontSize: 10, halign: 'center' },
    columnStyles: { 0: { halign: 'left' } },
    margin: { left: 20, right: 20 },
  });

  // Generate filename with current date
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = currentDate.toLocaleString('default', { month: 'short' });
  const year = currentDate.getFullYear();
  const filename = `meal-plan-${day}-${month}-${year}.pdf`;

  //console.log('PDF generation complete, saving file as:', filename);
  // Save the PDF with the new filename
  pdf.save(filename);
};
