import React, { useState, useEffect } from 'react';
import IngredientInput from './components/IngredientInput';
import MacroRatioSelector from './components/MacroRatioSelector';
import DietaryRestrictions from './components/DietaryRestrictions';
import MealPlan from './components/MealPlan';
import ShoppingList from './components/ShoppingList';
import { generateMealPlan, generateShoppingList, calculateNoRecipeMode } from './services/edamamService';
import { exportToPDF } from './utils/pdfExport';
import logo from './preppy-logo.svg';
import './App.css';
import { Loader2 } from 'lucide-react'; // Import the loading icon
import KofiButton from './KofiButton';
import NoRecipeModeIngredients from './components/NoRecipeModeIngredients';
import GoogleAd from './components/GoogleAd';


function App() {
  const [ingredients, setIngredients] = useState({ carbs: [], proteins: [], fats: [] });
  const [macroRatio, setMacroRatio] = useState('balanced');
  const [targetCalories, setTargetCalories] = useState(2000);
  const [numberOfPeople, setNumberOfPeople] = useState(1);
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [mealPlan, setMealPlan] = useState(null);
  const [shoppingList, setShoppingList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [useMetric, setUseMetric] = useState(true);
  const [restrictions, setRestrictions] = useState({});
  const [noRecipeMode, setNoRecipeMode] = useState(false);
  const [simpleRecipes, setSimpleRecipes] = useState(false);
  const [pdfExported, setPdfExported] = useState(false);
  const [calorieWarning, setCalorieWarning] = useState('');
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState(false);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [adjustedIngredients, setAdjustedIngredients] = useState([]);
  const [showAd, setShowAd] = useState(false);


  const handleIngredientsChange = (newIngredients) => {
    setAdjustedIngredients(newIngredients);
  };

  useEffect(() => {
    setMealPlan(null);
    setShoppingList(null);
  }, [noRecipeMode]);

  const handleTargetCaloriesChange = (e) => {
    const value = e.target.value;
    setTargetCalories(value);
    if (value === '') {
      setCalorieWarning('');
    } else {
      const calories = Number(value);
      setCalorieWarning(calories > 5000 ? "Slow down there champion!" : '');
    }
  };

  const handleNumberOfPeopleChange = (e) => {
    const value = e.target.value;
    setNumberOfPeople(value);
  };

  const handleNumberOfDaysChange = (e) => {
    const value = e.target.value;
    setNumberOfDays(value);
  };

  const handleBlur = (setter, minValue) => (e) => {
    const value = e.target.value;
    if (value === '' || Number(value) < minValue) {
      setter(minValue);
    } else {
      setter(Number(value));
    }
  };

  const disableButtonTemporarily = (setterFunction) => {
    setterFunction(true);
    setTimeout(() => {
      setterFunction(false);
    }, 5000); // 5 seconds
  };

  const handleGeneratePlan = async () => {
    if (generateButtonDisabled) return;
    setIsLoading(true);
    setError(null);
    setMealPlan(null);
    setShoppingList(null);
    setPdfExported(false);
    setShowAd(!noRecipeMode); 
    try {
      const allIngredients = [...ingredients.carbs, ...ingredients.proteins, ...ingredients.fats];
      if (allIngredients.length === 0) {
        throw new Error('Please select at least one ingredient');
      }
      if (allIngredients.length > 10) {
        throw new Error('Please select a maximum of 10 ingredients in total');
      }
      
      let plan, list;
      if (noRecipeMode) {
        plan = await calculateNoRecipeMode(allIngredients, macroRatio, targetCalories, numberOfPeople, numberOfDays);
        setAdjustedIngredients(plan.ingredients); // Set initial adjusted ingredients
        list = generateShoppingList({ ingredients: plan.ingredients }, numberOfPeople, numberOfDays, useMetric);
      } else {
        plan = await generateMealPlan(allIngredients, macroRatio, targetCalories, numberOfPeople, numberOfDays, restrictions, simpleRecipes);
        list = generateShoppingList(plan, numberOfPeople, numberOfDays, useMetric);
      }

      //console.log('Generated plan:', JSON.stringify(plan, null, 2));
      setMealPlan(plan);
      setShoppingList(list);
      setShowAd(true); 
      disableButtonTemporarily(setGenerateButtonDisabled);
    } catch (err) {
      //console.error('Error generating plan:', err);
      setError(`Failed to generate plan: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportToPDF = () => {
    if (exportButtonDisabled) return;
    disableButtonTemporarily(setExportButtonDisabled);
    setPdfLoading(true);
    if (mealPlan && shoppingList) {
      const dataToExport = noRecipeMode 
        ? { 
            ingredients: adjustedIngredients,
            numberOfPeople,
            numberOfDays,
            dailyTotals: mealPlan.dailyTotals // Assuming dailyTotals are available in mealPlan
          } 
        : {
            ...mealPlan,
            numberOfPeople,
            numberOfDays
          };
      
      const updatedShoppingList = noRecipeMode 
        ? generateShoppingList({ ingredients: adjustedIngredients }, numberOfPeople, numberOfDays, useMetric)
        : shoppingList;
      
      exportToPDF(dataToExport, updatedShoppingList, noRecipeMode)
        .then(() => {
          //console.log('PDF generated successfully');
          setPdfExported(true);
          setTimeout(() => {
            setPdfExported(false);
          }, 10000);
        })
        .catch(error => {
          //console.error('Error generating PDF:', error);
          setPdfExported(false);
        })
        .finally(() => {
          setPdfLoading(false);
        });
    }
  };

  const Disclaimer = ({ children }) => (
    <div className="text-sm text-gray-600 mt-1 mb-3 bg-gray-100 border-l-4 border-gray-400 p-2 rounded-r-md">
      {children}
    </div>
  );

  return (
    <div className="App bg-gray-100 min-h-screen">
      <header className="bg-black text-white py-4 shadow-md top-0">
        <div className="container mx-auto px-4 flex items-center justify-center">
          <a href="/" className="flex items-center">
            <img src={logo} alt="Preppy Logo" className="h-16 w-16 mr-3" />
            <h1 className="text-5xl font-bold">Preppy</h1>
          </a>
        </div>
      </header>
      <main className="container mx-auto px-4 py-8">
        <Disclaimer>
          This tool is not a replacement for a professional dietician or nutritionist. If you have specific dietary needs, always consult with a healthcare professional.
        </Disclaimer>

        <div className="bg-white rounded-lg shadow-lg p-6 mb-8 transition-all duration-300 hover:shadow-xl">
          <div className="mb-6 flex items-center justify-between">
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  checked={noRecipeMode}
                  onChange={(e) => setNoRecipeMode(e.target.checked)}
                  className="sr-only"
                />
                <div className="block bg-gray-300 w-14 h-8 rounded-full"></div>
                <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                  noRecipeMode ? 'transform translate-x-6 bg-gray-800' : ''
                }`}></div>
              </div>
              <span className="ml-3 text-gray-800 font-medium">No Recipe Mode</span>
            </label>
            <KofiButton />
          </div>
          
          {noRecipeMode && (
            <Disclaimer>
              <p><strong>Welcome to No Recipe Mode! Here's how it works:</strong></p>
              <ul style={{ paddingLeft: '20px', marginBottom: '10px' }}>
                <li>• Estimate ingredient quantities based on your calorie and macro goals</li>
                <li>• Fine-tune amounts to match your preferences</li>
                <li>• Use the lock feature to keep specific ingredients unchanged</li>
                <li>• Hit "Adjust to Target Calories" to auto-balance unlocked ingredients</li>
              </ul>
            </Disclaimer>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="targetCalories" className="block text-sm font-medium text-gray-700 mb-1">Target Daily Calories</label>
              <input
                type="number"
                id="targetCalories"
                value={targetCalories}
                onChange={handleTargetCaloriesChange}
                onBlur={handleBlur(setTargetCalories, 50)}
                className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors duration-200"
              />
              {calorieWarning && (
                <p className="text-yellow-600 text-sm mt-1">{calorieWarning}</p>
              )}
            </div>
            <div>
              <label htmlFor="numberOfPeople" className="block text-sm font-medium text-gray-700 mb-1">Number of People</label>
              <input
                type="number"
                id="numberOfPeople"
                value={numberOfPeople}
                onChange={handleNumberOfPeopleChange}
                onBlur={handleBlur(setNumberOfPeople, 1)}
                className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors duration-200"
              />
            </div>
            <div>
              <label htmlFor="numberOfDays" className="block text-sm font-medium text-gray-700 mb-1">Number of Days</label>
              <input
                type="number"
                id="numberOfDays"
                value={numberOfDays}
                onChange={handleNumberOfDaysChange}
                onBlur={handleBlur(setNumberOfDays, 1)}
                className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors duration-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Unit System</label>
              <div className="flex items-center space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio text-gray-800"
                    name="unitSystem"
                    value="metric"
                    checked={useMetric}
                    onChange={() => setUseMetric(true)}
                  />
                  <span className="ml-2">Metric</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio text-gray-800"
                    name="unitSystem"
                    value="imperial"
                    checked={!useMetric}
                    onChange={() => setUseMetric(false)}
                  />
                  <span className="ml-2">Imperial</span>
                </label>
              </div>
              <p className="text-xs text-gray-500 mt-1">
                {useMetric ? "kg, g, L, ml, etc." : "lb, oz, cups, etc."}
              </p>
            </div>
          </div>

          <IngredientInput ingredients={ingredients} setIngredients={setIngredients} />
          <Disclaimer>
            A maximum of 10 ingredients total is allowed.
          </Disclaimer>

          <MacroRatioSelector macroRatio={macroRatio} setMacroRatio={setMacroRatio} />

        {!noRecipeMode && (
            <>
              <DietaryRestrictions restrictions={restrictions} setRestrictions={setRestrictions} />
              <Disclaimer>
                While this tool offers filters for some dietary restrictions, always check ingredient lists carefully if you have specific food allergies or intolerances.
              </Disclaimer>
              <div className="mt-4 mb-4">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={simpleRecipes}
                    onChange={(e) => setSimpleRecipes(e.target.checked)}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span className="ml-2 text-gray-700">Search for easier recipes</span>
                </label>
              </div>
            </>
          )}

          <button
            onClick={handleGeneratePlan}
            disabled={isLoading || generateButtonDisabled}
            className="w-full bg-gray-800 text-white py-3 px-4 rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition duration-200 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Generating...' : `Generate ${noRecipeMode ? 'Plan' : 'Meal Plan'}`}
          </button>
        </div>

        {error && <p className="text-red-500 text-center mb-4 p-2 bg-red-100 rounded-md">{error}</p>}

{mealPlan && (
  <div className="mb-8 bg-white rounded-lg shadow-lg p-6">
    {noRecipeMode ? (
      <NoRecipeModeIngredients
        initialIngredients={mealPlan.ingredients}
        targetCalories={targetCalories}
        macroRatio={macroRatio}
        numberOfPeople={numberOfPeople}
        numberOfDays={numberOfDays}
        onIngredientsChange={handleIngredientsChange}
      />
    ) : (
      <MealPlan plan={mealPlan} showAd={showAd} />
    )}
    <Disclaimer>
      Calorie counts and nutritional information are estimates. Actual values may vary depending on specific ingredients and preparation methods.
    </Disclaimer>
    <div className="mt-4">
      <button
        onClick={handleExportToPDF}
        disabled={isLoading || exportButtonDisabled || pdfLoading}
        className="bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition duration-200 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
      >
        {pdfLoading ? (
          <>
            <Loader2 className="animate-spin mr-2" size={20} />
            Generating PDF...
          </>
        ) : pdfExported ? (
          'PDF Exported'
        ) : (
          'Export to PDF'
        )}
      </button>
      {(pdfLoading || pdfExported) && (
        <p className="text-sm text-gray-600 mt-2">
          If the PDF doesn't open automatically, please check your browser's pop-up settings and ensure they're enabled for this site.
        </p>
      )}
          </div>
                  </div>
        )}

        {shoppingList && Array.isArray(shoppingList) && (
          <div className="bg-white rounded-lg shadow-lg p-6">
            <ShoppingList 
              list={noRecipeMode 
                ? generateShoppingList({ ingredients: adjustedIngredients }, numberOfPeople, numberOfDays, useMetric)
                : shoppingList
              } 
            />
            <Disclaimer>
              Quantities in this shopping list are rounded for convenience. Please refer to individual recipes for precise measurements when cooking.
            </Disclaimer>
          </div>
        )}

        {!noRecipeMode && (
          <Disclaimer>
            This tool will generate meal plans based on available recipe descriptions. Some unwanted ingredients may still appear - please try generating a meal plan again if needed.
          </Disclaimer>
        )}
      </main>
      <footer className="bg-gray-200 py-6 mt-auto">
        <div className="container mx-auto px-4">
          <div id="edamam-badge" data-color="transparent" className="flex justify-center mb-6"></div>
<a
  href="mailto:eating@preppy-meals.com"
  style={{ textAlign: 'center', display: 'block' }}
              className="w-auto rounded-md  bg-gray-200 text-gray-400 py-1 px-1 -md focus:outline-none focus:ring-2 focus:ring-black-500 focus:ring-opacity-50 transition duration-600 ease-in-out"
>
  Contact Preppy
</a>
        </div>
      </footer>
    </div>
  );
}

export default App;