import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';


const INGREDIENT_SUGGESTIONS = {
  carbs: [
    // Grains
    "Rice", "Brown Rice", "Wild Rice", "Quinoa", "Barley", "Bulgur", "Couscous", "Millet", "Sorghum", "Teff", "Wheat Berries", "Farro", "Freekeh", "Amaranth", "Buckwheat", "Oats", "Cornmeal", "Polenta",
    // Pasta & Noodles
    "Pasta", "Spaghetti", "Linguine", "Fettuccine", "Penne", "Rotini", "Farfalle", "Orzo", "Lasagne", "Macaroni", "Ravioli", "Tortellini", "Ramen", "Udon", "Soba", "Rice Noodles", "Egg Noodles", "Gnocchi",
    // Breads
    "Bread", "Whole Wheat Bread", "Rye Bread", "Sourdough Bread", "Pita Bread", "Naan Bread", "Tortillas", "Bagels", "English Muffins", "Rolls", "Biscuits", "Cornbread", "Breadsticks", "Focaccia", "Ciabatta", "Brioche",
    // Starchy Vegetables
    "Potato", "Sweet Potato", "Yam", "Plantain", "Cassava", "Taro", "Yuca", "Butternut Squash", "Acorn Squash", "Pumpkin", "Spaghetti Squash", "Corn", "Peas", "Parsnips", "Rutabaga", "Celery Root", "Jicama",
    // Other Vegetables
    "Tomato", "Cucumber", "Broccoli", "Bell Pepper", "Chili Pepper", "Carrot", "Cauliflower", "Green Beans", "Zucchini", "Eggplant", "Asparagus", "Brussels Sprouts", "Cabbage", "Kale", "Spinach", "Collard Greens",
    "Swiss Chard", "Lettuce", "Arugula", "Endive", "Radicchio", "Watercress", "Beets", "Radishes", "Turnips", "Onions", "Shallots", "Garlic", "Leeks", "Scallions", "Artichokes", "Kohlrabi", "Okra", "Bok Choy", "Jackfruit", 
    // Mushrooms
    "Button Mushrooms", "Cremini Mushrooms", "Portobello Mushrooms", "Shiitake Mushrooms", "Oyster Mushrooms", "Enoki Mushrooms", "Chanterelle Mushrooms", "Porcini Mushrooms", "Morel Mushrooms", "Truffle Mushrooms",
    // Fruits
    "Banana", "Apple", "Orange", "Strawberries", "Blueberries", "Raspberries", "Blackberries", "Mango", "Pineapple", "Papaya", "Kiwi", "Grapes", "Peach", "Nectarine", "Plum", "Apricot", "Cherries", "Pear", "Figs", "Dates",
    "Pomegranate", "Guava", "Passion Fruit", "Lychee", "Coconut", "Watermelon", "Cantaloupe", "Honeydew", "Lemon", "Lime",
  ],
  proteins: [
    // Meats
    "Chicken", "Beef", "Turkey", "Pork", "Lamb", "Duck", "Goose", "Venison", "Rabbit", "Bison", "Goat", "Veal", "Liver",
    // Seafood
    "Fish", "Salmon", "Tuna", "Cod", "Halibut", "Tilapia", "Sardines", "Mackerel", "Anchovies", "Herring", "Trout", "Bass", "Catfish", "Swordfish", "Mahi Mahi", "Red Snapper", "Grouper", "Shrimp", "Prawns", "Lobster", "Crab",
    "Clams", "Mussels", "Oysters", "Scallops", "Squid", "Octopus",
    // Eggs & Dairy
    "Eggs", "Egg Whites", "Greek Yogurt", "Yogurt", "Kefir", "Cottage Cheese", "Ricotta Cheese", "Mozzarella", "Cheddar", "Parmesan", "Feta Cheese", "Goat Cheese", "Quark", "Cream Cheese", "Whey Protein", "Casein Protein",
    // Plant-based Proteins
    "Tofu", "Tempeh", "Seitan", "Edamame", "Lentils", "Chickpeas", "Black Beans", "Kidney Beans", "Navy Beans", "Pinto Beans", "Lima Beans", "Mung Beans", "Adzuki Beans", "Fava Beans", "Soybeans", "Split Peas", "Hemp Seeds",
    "Chia Seeds", "Pumpkin Seeds", "Sunflower Seeds", "Sesame Seeds", "Flax Seeds", "Almonds", "Cashews", "Walnuts", "Pistachios", "Brazil Nuts", "Hazelnuts", "Pine Nuts", "Macadamia Nuts", "Peanuts", "Quorn", "Nutritional Yeast",
    "Spirulina", "Textured Vegetable Protein (TVP)"
  ],
  fats: [
    // Plant-based Oils
    "Olive Oil", "Coconut Oil", "Avocado Oil", "Grapeseed Oil", "Sunflower Oil", "Safflower Oil", "Canola Oil", "Peanut Oil", "Sesame Oil", "Flaxseed Oil", "Walnut Oil", "Almond Oil", "Hazelnut Oil", "Palm Oil", "MCT Oil",
    // Nuts & Seeds
    "Almonds", "Walnuts", "Pecans", "Pistachios", "Cashews", "Macadamia Nuts", "Brazil Nuts", "Hazelnuts", "Pine Nuts", "Peanuts", "Pumpkin Seeds", "Sunflower Seeds", "Sesame Seeds", "Chia Seeds", "Flax Seeds", "Hemp Seeds",
    "Tahini", "Peanut Butter", "Almond Butter", "Cashew Butter",
    // Fruits
    "Avocado", "Olives", "Coconut",
    // Animal-based Fats
    "Butter", "Ghee", "Lard", "Tallow", "Duck Fat", "Chicken Fat", "Goose Fat", "Beef Tallow", "Fish Oil",
    // Dairy
    "Cheese", "Cream", "Sour Cream", "Creme Fraiche", "Mascarpone",
    // Fish
    "Salmon", "Sardines", "Anchovies", "Herring", "Mackerel", "Trout",
    // Other
    "Chocolate", "Cocoa Powder", "Cacao Nibs", "Mayonnaise", "Light Mayonnaise", "Egg Yolks", "Bone Marrow"
  ]
};


const PLACEHOLDER_TEXTS = {
  carbs: "Start typing! For example: bread, rice, vegetables, fruits, etc",
  proteins: "Start typing! For example: meats, seafood, beans, tofu, eggs, dairy, etc",
  fats: "Start typing! For example: oils, butters, avocado, nuts, seeds, etc"
};

const IngredientButton = ({ ingredient, onRemove }) => (
  <button
    className="flex items-center px-3 py-1 m-1 text-sm text-gray-700 bg-gray-100 border border-gray-300 rounded-full hover:bg-gray-200 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-400"
    onClick={() => onRemove(ingredient)}
  >
    <span className="mr-1">{ingredient}</span>
    <X size={14} className="text-gray-500" />
  </button>
);

const MAX_INGREDIENTS = 10;

function IngredientInput({ ingredients, setIngredients }) {
  const [inputs, setInputs] = useState({ carbs: '', proteins: '', fats: '' });
  const [suggestions, setSuggestions] = useState({ carbs: [], proteins: [], fats: [] });
  const [totalIngredients, setTotalIngredients] = useState(0);

  useEffect(() => {
    const total = Object.values(ingredients).flat().length;
    setTotalIngredients(total);
  }, [ingredients]);

  const handleInputChange = (category, value) => {
    setInputs(prev => ({ ...prev, [category]: value }));

    if (value.length > 0) {
      const filteredSuggestions = INGREDIENT_SUGGESTIONS[category].filter(
        ingredient => ingredient.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(prev => ({ ...prev, [category]: filteredSuggestions }));
    } else {
      setSuggestions(prev => ({ ...prev, [category]: [] }));
    }
  };

  const handleAddIngredient = (category, ingredient) => {
    if (totalIngredients >= MAX_INGREDIENTS) {
      alert(`You can only select up to ${MAX_INGREDIENTS} ingredients in total.`);
      return;
    }
    if (!ingredients[category].includes(ingredient)) {
      setIngredients(prev => ({
        ...prev,
        [category]: [...prev[category], ingredient]
      }));
    }
    setInputs(prev => ({ ...prev, [category]: '' }));
    setSuggestions(prev => ({ ...prev, [category]: [] }));
  };

  const handleRemoveIngredient = (category, ingredient) => {
    setIngredients(prev => ({
      ...prev,
      [category]: prev[category].filter(ing => ing !== ingredient)
    }));
  };

  const renderInputSection = (category) => (
    <div className="mb-4">
      <h3 className="text-lg font-medium mb-2 capitalize text-gray-800">{category}</h3>
      <div className="relative">
        <input
          type="text"
          value={inputs[category]}
          onChange={(e) => handleInputChange(category, e.target.value)}
          placeholder={PLACEHOLDER_TEXTS[category]}
          className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 transition-colors duration-200"
        />
        {suggestions[category].length > 0 && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto shadow-lg">
            {suggestions[category].map((suggestion, index) => (
              <li 
                key={index} 
                onClick={() => handleAddIngredient(category, suggestion)}
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer transition-colors duration-150"
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="mt-2 flex flex-wrap">
        {ingredients[category].map((ingredient, index) => (
          <IngredientButton
            key={index}
            ingredient={ingredient}
            onRemove={() => handleRemoveIngredient(category, ingredient)}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="mb-6">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Ingredients</h2>
      <p className="text-sm text-gray-600 mb-4">
        Selected: {totalIngredients}/{MAX_INGREDIENTS}
      </p>
      {renderInputSection('carbs')}
      {renderInputSection('proteins')}
      {renderInputSection('fats')}
    </div>
  );
}

export default IngredientInput;