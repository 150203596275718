import React from 'react';

const KofiButton = () => {
  return (
    <a
      href="https://ko-fi.com/I2I113P9HG"
      target="_blank"
      rel="noopener noreferrer"
      className="bg-[#000000] hover:bg-[#000000]/80 text-white text-sm font-bold py-1 px-2 rounded inline-flex items-center transition-colors duration-200 group"
    >
      <span className="w-4 h-3 mr-1 relative overflow-hidden">
        <img
          src="https://storage.ko-fi.com/cdn/cup-border.png"
          alt="Ko-fi donations"
          className="w-4 h-3 absolute top-0 left-0 group-hover:animate-kofi-wiggle"
        />
      </span>
      <span>Support Preppy</span>
      <style jsx>{`
        @keyframes kofi-wiggle {
          0% { transform: rotate(0deg); }
          15% { transform: rotate(-15deg); }
          30% { transform: rotate(15deg); }
          45% { transform: rotate(-15deg); }
          60% { transform: rotate(15deg); }
          75% { transform: rotate(-15deg); }
          100% { transform: rotate(0deg); }
        }
        .group:hover .group-hover\\:animate-kofi-wiggle {
          animation: kofi-wiggle 1s ease-in-out;
        }
      `}</style>
    </a>
  );
};

export default KofiButton;