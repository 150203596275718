import React from 'react';
import GoogleAd from './GoogleAd'; // Import the GoogleAd component

function MealPlan({ plan, showAd }) {
  if (!plan || !plan.mealPlan) {
    return null;
  }

  const { mealPlan, dailyTotals, numberOfDays, numberOfPeople } = plan;

  // Function to render a meal card (extracted for reusability)
  const renderMealCard = (meal, index) => (
    <div key={index} className="bg-gray-50 rounded-lg p-4 shadow">
      <img src={meal.image} alt={meal.title} className="w-full h-48 object-cover rounded-md mb-4" />
      <h4 className="font-bold text-lg mb-2">{meal.title}</h4>
      <p className="text-sm text-gray-600 mb-2">Nutritional info (per serving):</p>
      <ul className="text-sm text-gray-600">
        <li>Calories: {meal.calories || 'N/A'}</li>
        <li>Protein: {meal.protein || 'N/A'}g</li>
        <li>Carbs: {meal.carbs || 'N/A'}g</li>
        <li>Fat: {meal.fat || 'N/A'}g</li>
        <li>Fiber: {meal.fiber || 'N/A'}g</li>
      </ul>
      <a 
        href={meal.url} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-500 hover:text-blue-600 mt-2 inline-block transition duration-150 ease-in-out"
      >
        View Recipe
      </a>
    </div>
  );

  // Function to render the Google Ad card
  const renderAdCard = () => (
    <div className="bg-gray-50 rounded-lg p-4 shadow">
      <h4 className="font-bold text-lg mb-2">Sponsored Content</h4>
      <GoogleAd />
    </div>
  );

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Your Meal Plan</h2>
      <p className="text-lg mb-4">This meal plan repeats for {numberOfDays} day(s) for {numberOfPeople} person(s)</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        {mealPlan.map((meal, index) => renderMealCard(meal, index))}
        {showAd && renderAdCard()}
      </div>
      {dailyTotals && (
        <div className="bg-gray-100 rounded-lg p-4 mt-8">
          <h3 className="font-bold text-lg mb-3">Daily Totals (per person):</h3>
          <ul>
            <li>Total Calories: {Math.round(dailyTotals.calories)}</li>
            <li>Total Protein: {Math.round(dailyTotals.protein)}g</li>
            <li>Total Carbs: {Math.round(dailyTotals.carbs)}g</li>
            <li>Total Fat: {Math.round(dailyTotals.fat)}g</li>
            {dailyTotals.fiber !== undefined && <li>Total Fiber: {Math.round(dailyTotals.fiber)}g</li>}
          </ul>
        </div>
      )}
    </div>
  );
}

export default MealPlan;